import React from "react";

function IconScrollUp() {
    return <svg className="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" d="M12 6v13m0-13 4 4m-4-4-4 4" />
    </svg>
        ;
}

export default IconScrollUp;
