import React, { useEffect, useRef } from "react";
import ServicesList from "./ui/ServicesList";
import MyWorkSection from "./MyWorkSection";
import AboutMe from "./ui/AboutMe";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);

export default function MyServices() {
    const divRef = useRef(null);

    useEffect(() => {

        let ctx = gsap.context(() => {

            // ScrollTrigger.normalizeScroll(true);
            // ScrollTrigger.config({
            //     ignoreMobileResize: true,
            // })

            gsap.fromTo(
                divRef.current,
                {
                    scale: 1,
                    y: 0
                },
                {
                    scale: window.innerWidth <= 768 ? 0.85 : 0.95,
                    y: window.innerWidth <= 768 ? -50 : -100, // Adjust this value to move the div down
                    ease: 'power1.out', // Easing for smooth animation
                    scrollTrigger: {
                        trigger: divRef.current,
                        start: "bottom bottom", // Start animation when the bottom of the div reaches the bottom of the viewport
                        end: "bottom+=400 ", // Adjust this value if needed
                        scrub: true,
                        // markers: false, // Optional: remove this line once you confirm it's working
                        toggleActions: "play none none none"
                    },

                }
            );
        })
        return () => ctx.revert(); // <- cleanup!
    }, []);

    return (
        <div ref={divRef} className='bg-black-noise w-full h-full rounded-[24px] p-8 text-portfolio-gray-accent'>
            <div className="h-full w-full flex flex-col gap-4 md:gap-12 py-10 md:py-20">
                <h1 id="services" className='text-[6vw] md:text-[7vw] xl:text-[7.5vw] font-medium'>HOW I CAN HELP YOU /</h1>

                <div className="flex flex-col md:flex-row my-10">
                    <div className="hidden md:flex w-9/12"></div>
                    <div className=' text-lg flex w-full'>
                        <div className="flex flex-col lg:flex-row gap-4 md:gap-10 w-full xl:max-w-screen-sm">
                            <span className='font-Montreal-Mono opacity-50'>(SERVICES)</span>
                            <p className="font-Montreal-Regular text-[1rem] md:text-[1.25rem]">Frustrated with websites that don't reflect your brand or drive growth? I craft premium web experiences that captivate and help you focus on growing your business.</p>
                        </div>
                        <div className="hidden md:flex md:w-6/12 lg:w-4/12 xl:w-5/12 2xl:w-8/12"></div>
                    </div>
                </div>
                <ServicesList />
                <MyWorkSection />
                <AboutMe />
            </div>
        </div>
    );
}
