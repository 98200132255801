import React from "react";
import servicesList from "../../../../assets/data/servicesList";

function ServicesList() {

    return <div className="z-0 relative">
        {
            servicesList.map((service, key) => (
                <div key={key} className={`h-full w-full flex flex-col gap-2 bg-black-noise border-t-[0.5px] border-portfolio-black-accent pt-10 mb-20  `}
                    style={
                        {
                            position: 'sticky',
                            top: `calc(15vh + ${key * 4.75}em)`,
                            marginBottom: `calc(10vh - ${service.id * 1.75})`
                        }
                    }
                >
                    <div className="flex md:flex-row w-full text-2xl text-portfolio-gray-accent gap-4">
                        <h1 className="text-2xl md:text-5xl font-Montreal-Bold w-4/12 md:w-5/12">( 0{key + 1} )</h1>
                        <div className="flex justify-between w-full md:w-7/12">
                            <h1 className="font-Montreal-Bold text-2xl md:text-5xl font-bold">{service.title}</h1>
                            <span className="flex justify-end items-center opacity-20 font-Montreal-Mono text-end hidden md:block text-4xl" dangerouslySetInnerHTML={{ __html: service.symbol }}></span>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row w-full gap-6 pt-10">
                        <div className="w-5/12"></div>
                        <div>
                            <div className="flex flex-col gap-4 max-w-sm">
                                <p className="text-sm text-portfolio-gray-accent">{service.paragraph}</p>
                                <div>
                                    <ul>
                                        {
                                            service.services.map((item, index) => (
                                                <div className="flex gap-2" key={item+index}>
                                                    <h2 className="font-Montreal-mono text-base md:text-lg font-thin opacity-45">0{index + 1}. </h2>
                                                    <h2 className="font-Montreal-Regular font-bold text-base md:text-2xl">{item}</h2>
                                                </div>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            ))
        }
    </div>;
}

export default ServicesList;
