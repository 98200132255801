import './globals.css';
import Hero from './components/Home/Hero';
import Navbar from './components/Navbar/Navbar';
import MyServices from './components/Home/black-section/MyServices';

import Lenis from '@studio-freight/lenis'

import { useEffect, useState } from 'react';
import ContactMe from './components/Home/ContactMe';
import Footer from './components/Footer/Footer';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap';

function App() {

  const isMobile = window.innerWidth <= 768;

  const lenis = new Lenis({
    duration: isMobile ? 1.25 : 3.5,
  })

  useEffect(() => {

    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)


    let ctx = gsap.context(() => {

      ScrollTrigger.normalizeScroll(true);
      ScrollTrigger.config({
        ignoreMobileResize: true,
      })
    })

    return () => ctx.revert();

  }, []);





  return (
    <div className="bg-biege-noise h-full w-full font-Montreal-Regular">
      <Navbar lenis={lenis} />
      <div className='h-full w-full max-w-screen relative flex flex-col'>
        <Hero lenis={lenis} />
        <MyServices />
      </div>
      {/* <Testimonials /> */}
      <h1 className='text-portfolio-black opacity-90 font-Montreal-Regular font-extrabold text-2xl md:text-5xl lg:text-[11vh] xl:text-[13vh] text-center -mt-[20dvh] md:-mt-[10vh] uppercase'>Let's Bring Your Vision to Life /</h1>
      <div className='max-w-screen-md mx-auto my-10 p-6 flex flex-col w-full items-center justify-center text-center gap-4'>
        <h1 className='text-xl md:text-4xl font-Montreal-Bold text-center text-portfolio-black opacity-70'>"Design is not just what it looks like and feels like. Design is how it works."</h1>
        <h4 className='font-Montreal-Mono text-lg md:text-3xl text-portfolio-black-accent text-opacity-60 '>Steve Jobs</h4>
      </div>
      {/* contact */}
      <ContactMe />
      <Footer />

    </div >
  );
}

export default App;
