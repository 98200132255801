import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import myWorkList from '../../../../assets/data/myworkList';
import gsap from 'gsap';

const MyWork = () => {
    const imagesRef = useRef([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [scrollDirection, setScrollDirection] = useState('down');

    const hoverRef = useRef(null);
    const cursorRef = useRef(null);

    // detect scroll direction
    useEffect(() => {
        let lastScrollTop = window.pageYOffset;

        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            if (scrollTop > lastScrollTop) {
                setScrollDirection('down');
            } else {
                setScrollDirection('up');
            }
            lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // check if image is in view
    useEffect(() => {
        const observers = imagesRef.current.map((img, index) => {
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setCurrentIndex(index + 1);
                    }
                });
            }, {
                threshold: 0.5 // Adjust based on when you want the number to change (50% visibility)
            });

            observer.observe(img);

            return observer;
        });

        return () => {
            observers.forEach(observer => observer.disconnect());
        };
    }, []);

    return (
        <div className="flex flex-row justify-between min-h-screen h-full w-full relative my-10 md:my-24 lg:my-32">
            <div className='w-9/12 lg:w-9/12 text-[15vh] lg:text-[40vh] font-Montreal-Mono font-medium text-portfolio-gray-accent hidden md:flex sticky top-0 h-[45vh] z-10 '>
                <div className='flex gap-1'>
                    <span>0</span>
                    <div className='flex flex-col h-[25] lg:h-[45vh] overflow-hidden'>
                        <AnimatePresence mode='wait'>
                            <motion.div
                                key={currentIndex}
                                initial={{ y: scrollDirection === 'down' ? 100 : -100, opacity: 1 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: scrollDirection === 'down' ? -100 : 100, opacity: 1 }}
                                transition={{ duration: 0.3, ease: 'linear' }}
                                style={{ display: 'block' }}
                            >
                                {currentIndex + '.'}
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
            <div className='h-full w-full md:w-full overflow-y-hidden flex flex-col gap-24 lg:gap-24 pt-32 md:pt-0 '>
                {myWorkList.map((img, key) => (
                    <div className='flex flex-col gap-4' key={key}>
                        <a target="_blank" href={img.url} className={` rounded-md h-[65vh] md:h-[75vh] lg:h-[85vh] w-full p-4 flex relative items-center`}
                            style={{
                                backgroundColor: img.bg
                            }}
                            ref={hoverRef} rel="noreferrer"
                        >
                            <h1 className='text-[15vh] absolute -top-20 md:-top-32 left-4 z-10 flex md:hidden'>0{(key + 1) + '.'}</h1>
                            {/* number scroll trigger point */}
                            <div className='absolute top-[70%]' ref={el => imagesRef.current[key] = el}></div>
                            {/* ---- */}
                            <img
                                key={key}
                                src={img.imgSrc}
                                alt={`project_${img.title}`}
                                className='h-fit md:h-5/6 w-full object-contain rounded-lg '
                            />
                        </a>
                        <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-4'>
                            <h1 className='text-lg lg:text-2xl font-Montreal-Bold text-portfolio-gray-accent tracking-wider'>{img.title}</h1>
                            <div className='flex gap-2 flex-wrap items-center justify-start lg:justify-end'>
                                {
                                    img.tags.map((tag, key) => (
                                        <span className='rounded-full  py-1 px-2 text-sm font-Montreal-Mono border-[1px] text-opacity-20 border-portfolio-gray-darker uppercase' key={key}>{tag}</span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MyWork;
