import React, { useState } from "react";

function SidebarItem({ showFullscreen, menu, closeFullscreen }) {

    const [isHovered, setIsHovered] = useState(false)

    return <div onClick={closeFullscreen} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className="text-4xl 2xl:text-[3.5rem] font-Montreal-Bold h-10 md:h-[3rem] overflow-hidden w-44 md:w-[30vw] flex gap-1 relative">
        <div className={` absolute left-0 ${showFullscreen ? 'opacity-1 top-0' : 'top-10 opacity-0'} delay-300 duration-[0.5s] transition-all ease-in-out ${isHovered ? 'left-6' : 'left-0'}`}>
            {/* <span>.</span> */}
            <a href={menu.url}>{menu.title}</a>
        </div>

        <div className={`absolute left-0 ${isHovered ? 'opacity-40' : 'opacity-0'} text-2xl  font-Montreal-Mono text-portfolio-gray-accent flex items-center h-full transition-all duration-500 delay-200 ease-out`}>&#10022;</div>
    </div>;
}

export default SidebarItem;
