import React from "react";

function Loading({ isLoading, animateLoadingText }) {

    return <div className={`fixed left-0 h-screen w-screen ${isLoading ? 'top-0 rounded-b-none z-[999999] ' : ' rounded-[50%] md:rounded-b-[2000px] -top-[100vh] z-0'} bg-black text-portfolio-gray-accent flex items-center justify-center transition-all duration-[0.6s] ease-in-out `}>
        <div className=' h-40 overflow-hidden'>
            <div className='flex flex-col gap-2 h-40 overflow-hidden'>

                <div className={`h-16 lg:h-[6vw] w-60 relative ${isLoading ? 'top-0 opacity-1' : 'opacity-0 -top-40'} transition-all duration-700 ease-in-out overflow-hidden rounde-full`}>
                    {
                        ("Loading").split("").map((letter, index) => (
                            <h1
                                className={`text-[3rem] lg:text-[2.5rem] xl:text-[3rem] 2xl:text-[3.5rem] font-Montreal-Regular grayscale-[1] transition-all duration-500 ease-in-out absolute ${animateLoadingText ? 'top-0 opacity-100' : 'top-40 opacity-0'} text-portfolio-gray`}
                                key={letter + index}
                                style={{
                                    rotate: `${index * Math.floor(Math.random() * 200)}deg`,
                                    left: `${index * 60}px`, // Adjust this value as needed for proper spacing
                                    transitionDelay: `${index * 150}ms`
                                }}
                            >
                                <span className="inline-block ">&#128062;</span>
                            </h1>
                        ))
                    }
                </div>

                <div className={`h-10 relative ${isLoading ? 'top-0 opacity-1' : 'opacity-0 -top-40'} transition-all duration-700 ease-in-out overflow-hidden`}>
                    <h2 className='font-Montreal-Mono text-xl font-thin text-center opacity-40'>Portfolio @ 2024</h2>
                </div>
            </div>
        </div>


    </div >;
}

export default Loading;
