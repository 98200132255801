import React from "react";
import ArrowAbout from "../../../../assets/icons/arrowAbout";

function AboutMe() {
    return <div className="flex flex-col" id="about">
        <div className="flex items-start">
            <div className="w-5/12 2xl:w-5/12 hidden lg:flex"><ArrowAbout /></div>
            <div className="w-6/12 2xl:w-6/12">
                <h1 className="text-[3rem] md:text-[4rem] lg:text-[5.5rem] xl:text-[6.5vw] leading-tight font-Montreal-Regular uppercase">Developer, Designer</h1>
            </div>
            <div className="w-1/12 "></div>
        </div>
        <div className="flex flex-col gap-10 md:flex-row justify-between mt-10 px-0 lg:px-10">
            <div className="w-full md:w-6/12 2xl:w-5/12 h-full flex justify-center md:justify-start">
                {/* <img src="/images/dp-3.jpeg" alt="dp" className="h-[500px] w-[400px] 2xl:h-[50rem] 2xl:w-[40rem] rounded-md object-cover saturate-50" /> */}
            </div>
            <div className="w-full md:w-7/12 2xl:w-6/12">
                <p className="font-Montreal-Regular text-[1rem] md:text-[1.25rem]">With a passion for design and development, I take projects from ideation to launch, ensuring a seamless journey that leaves a lasting positive impact on the digital landscape and your business.</p>
                <div className="flex flex-col lg:flex-row gap-4 lg:gap-10 mt-10">
                    <span className="text-portfolio-gray text-sm 2xl:text-2xl font-Montreal-Mono opacity-50 w-2/6 md:w-3/4 lg:w-1/4">( ABOUT ME )</span>
                    <p className="w-full md:w-72 lg:w-80 2xl:w-7/12 opacity-70 text-base 2xl:text-2xl">I specialize in crafting digital experiences that are both intuitive and visually compelling. My passion lies in blending creativity with technology to create designs that not only look great but also elevate user interaction. When I'm not coding or designing, I enjoy exploring new tools and techniques, contributing to open-source communities, and continuously refining my skills to stay at the forefront of the industry.</p>
                </div>
            </div>
            <div className="w-1/12 "></div>
        </div>

    </div>;
}

export default AboutMe;
