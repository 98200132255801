import React from "react";

function ArrowAbout() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      ariaHidden="true"
      viewBox="0 0 24 24"
      className="h-[6rem] w-[6rem] text-portfolio-gray-darker -rotate-[60deg] left-[10rem] top-[4rem] relative"
    >
      <path stroke="currentColor" d="M12 19V5m0 14l-4-4m4 4l4-4"></path>
    </svg>
  );
}

export default ArrowAbout;