import React from "react";
import MenuLinks from "../../../assets/data/menuLinks";
import socialLinks from "../../../assets/data/socials";
import HexagonSVG from "../../../assets/icons/hexagonSVG";
import SidebarItem from "./SidebarItem";

function Sidebar({ showFullscreen, closeFullscreen }) {
    return <div className={`fixed top-0 left-0 ${showFullscreen ? 'z-[99]' : '-z-10'} h-screen w-screen bg-portfolio-black-accent-dark backdrop-blur-[1px] bg-opacity-5 transition-all duration-150 ease-in-out overflow-hidden`}>
        <div className={`fixed overflow-hidden pt-[15vh] top-0 ${showFullscreen ? 'right-0' : '-right-[100vw] md:-right-[60vw] lg::-right-[40vw]'} h-screen w-[100vw] md:w-[60vw] lg:w-[40vw] bg-[#2b2a26] rounded-l-md transition-all duration-[0.3s] ease-in-out p-10`}>
            <ul className={`space-y-6 text-white text-3xl flex flex-col ${showFullscreen ? 'opacity-1' : 'opacity-0'} duration-[0.5s] transition-all ease-in-out gap-1 z-30 relative`} >
                {
                    MenuLinks.map((menu, key) => (
                        <SidebarItem key={key} menu={menu} showFullscreen={showFullscreen} closeFullscreen={closeFullscreen} />
                    ))
                }
            </ul>

            <div className="flex-col flex mt-[10vh] 2xl:mt-[20vh] relative z-30">
                <div className="flex flex-wrap gap-4 max-w-60">
                    {
                        socialLinks.map((social, key) => (
                            <a target={'_blank'} href={social.url} key={key} className="px-4 tracking-wider py-1 rounded-full border-portfolio-gray-accent uppercase font-bold text-portfolio-gray hover:bg-portfolio-gray-accent hover:text-portfolio-black transition-all duration-200 ease-in-out border-[1px] font-Montreal-Mono text-xs" rel="noreferrer">
                                {social.title}
                            </a>
                        ))
                    }
                </div>
            </div>

            {/* bubble */}
            <div className={`absolute h-full saturate-0 w-full -top-[10vw]  ${showFullscreen ? 'opacity-1 -right-[30vw] md:-right-[20vw]' : 'opacity-0 md:-right-[60vw]'} duration-[0.5s] transition-all ease-in-out opacity-50`}>
                <svg className="absolute bottom-0 -right-20" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                    <path fill="#5D7160" d="M53.2,-73.2C67.9,-62.7,77.8,-45.6,80.7,-28.2C83.7,-10.8,79.6,6.9,72.9,22.5C66.3,38.2,56.9,51.9,44.4,61C31.8,70.1,15.9,74.7,-1,76.1C-18,77.6,-35.9,75.9,-49.8,67.2C-63.6,58.5,-73.4,42.7,-80,25.3C-86.6,7.9,-90,-11.3,-85.8,-28.9C-81.5,-46.6,-69.5,-62.8,-53.9,-73C-38.3,-83.2,-19.1,-87.4,0.1,-87.5C19.3,-87.6,38.6,-83.7,53.2,-73.2Z" transform="translate(100 100)" />
                </svg>
                {/* <HexagonSVG /> */}
            </div>
        </div>
    </div>;
}

export default Sidebar;
