const myWorkList = [
    {
        imgSrc: '/images/work/bean.webp',
        url: 'https://bean.la',
        bg: '#4e4c4f',
        title: 'Bean',
        tags: [
            "Nuxt", "TAILWIND.CSS", "SANITY"
        ]
    },
    {
        imgSrc: '/images/work/ghia-1.webp',
        url: 'https://drinkghia.com',
        bg: '#A95C68',
        title: 'DrinkGhia',
        tags: [
            "Shopify", "CSS"
        ]
    },
    {
        imgSrc: '/images/work/antiraid-1.webp',
        url: 'https://www.antiraid.ai/',
        bg: '#72668a',
        title: 'AntiRaid.Ai',
        tags: [
            "React", "TAILWIND.CSS"
        ]
    },
    // {
    //     imgSrc: '/images/work/gigis-1.webp',
    //     url: 'https://gigis.la',
    //     bg: '#9f8d8d',
    //     title: 'Gigis',
    //     tags: [
    //         "Vue.JS", "SCSS", "SANITY"
    //     ]
    // },
    // {
    //     imgSrc: '/images/work/blackswan.webp',
    //     url: 'https://www.blackswan.biz/',
    //     bg: '#9f8d8d',
    //     title: 'BlackSwan',
    //     tags: [
    //         "Next.JS", "Tailwind"
    //     ]
    // },

    {
        imgSrc: '/images/work/bilingualjokes.webp',
        url: 'https://www.bilingualjokes.com/',
        bg: '#9f8d8d',
        title: 'Bilingual Jokes',
        tags: [
            "Html", "CSS", 'Javascript'
        ]
    },
    // {
    //     imgSrc: '/images/work/salon94.webp',
    //     url: 'https://www.salon94design.com/',
    //     bg: '#9a9f8f',
    //     title: 'Salon94',
    //     tags: [
    //         "Nuxt", "Tailwind", 'Sanity'
    //     ]
    // }
]

export default myWorkList;