import React, { useState } from "react";
import { motion } from 'framer-motion';

export default function FooterLink({ title, url, isSocialLink }) {

    const [hovered, setHovered] = useState(false);

    return <motion.a target={isSocialLink && '_blank'} href={url} className="text-portfolio-black-accent font-Montreal-Mono font-thin h-4 2xl:h-[0.95rem] overflow-hidden relative capitalize w-fit"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
    >
        <span className="transition-all duration-300 ease-in-out relative"
            style={{
                top: hovered ? -15 : 0
            }}
        >
            {title}
        </span>
        <span className="transition-all duration-300 ease-in-out absolute left-0"
            style={{
                top: hovered ? 0 : 15
            }}
        >
            {title}
        </span>
    </motion.a>;
}
