import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import MenuLinks from "../../assets/data/menuLinks";
import NavLink from "./NavLink";
import { motion } from 'framer-motion'
import Sidebar from "./Sidebar/Sidebar";
import LogoSVG from "../../assets/icons/LogoSvg";

export default function Navbar({ lenis }) {

    const divRef = useRef(null);
    const [showBurger, setShowBurger] = useState(false);
    const [showFullscreen, setShowFullscreen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const y = window.scrollY - 50;
            if (y > 5) {
                gsap.to(divRef.current, {
                    translateY: window.innerWidth < 768 ? -120 : -80,
                    duration: 0.5, // Smooth transition duration
                    ease: "power2.out", // Easing function for smoothness
                });
            } else {
                gsap.to(divRef.current, {
                    translateY: 0,
                    duration: 0.5,
                    ease: "power2.out",
                });
            }
            // show burger bsed on scroll position 
            if (y > 750) {
                setShowBurger(true)
            }
            else {
                setShowBurger(false)
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);



    useEffect(() => {
        if (showFullscreen) {
            lenis.stop();
            document.body.style.overflow = "hidden";
        }
        else {
            lenis.start();
            document.body.style.overflow = "unset";
        }
        // console.log('lenis : ', lenis)
        return () => {
            lenis.stop()
        };
    }, [showFullscreen]);


    // loading animation
    // useEffect(() => {
    //     // let ctx = gsap.context(() => {
    //     if (isLoading) {
    //         gsap.to(divRef.current, {
    //             translateY: window.innerWidth < 768 ? -120 : -80,
    //             duration: 0.5, // Smooth transition duration
    //             delay: 0.45,
    //             ease: "power2.out", // Easing function for smoothness
    //         });
    //     } else {
    //         gsap.to(divRef.current, {
    //             translateY: 0,
    //             delay: 0.25,
    //             duration: 0.45,
    //             ease: "power2.out",
    //         });
    //     }

    //     // })
    //     // return () => ctx.revert();
    // }, [isLoading]);


    const closeFullscreenOnLink = () => {
        setShowFullscreen(false);
    }


    return (
        <>
            <div className='fixed top-0 left-0 w-full p-4 px-6 bg-transparent flex justify-between font-Montreal-Mono text-xs md:text-[1rem] uppercase z-50' ref={divRef}>
                {/* logo */}
                <div className="flex flex-col md:flex-row gap-4 items-start md:items-center ">
                    <a href="#" className=' font-Montreal-Regular text-[1.5rem]'>ANEES</a>
                    <div className="ml-0 md:ml-8">
                        <h1 className="text-portfolio-black-accent font-medium text-[12px] md:text-base">( Frontend Developer & Designer )</h1>
                    </div>
                </div>
                {/* nav items */}
                <div className='flex flex-col md:flex-row gap-1 items-center'>
                    {
                        MenuLinks.map((menu, key) => (
                            <NavLink key={key} title={menu.title} url={menu.url} last={(MenuLinks.length - 1) === key} />
                        ))
                    }
                </div>
            </div>


            {/* mobile burger */}
            <button onClick={() => setShowFullscreen(!showFullscreen)} className={`fixed top-6 right-6 ${showBurger ? 'z-[200]' : 'z-0'} rounded-full h-16 w-16 bg-portfolio-black-accent-dark ${showBurger ? 'opacity-100 scale-100' : 'opacity-0 scale-50'} transition-all duration-300 ease-in-out flex items-center justify-center text-center`} >
                <motion.div
                    className="absolute w-6 pointer-events-none h-[1px] bg-portfolio-gray-accent"
                    animate={showFullscreen ? { rotate: 45, y: 0 } : { rotate: 0, y: -5 }}
                    transition={{ duration: 0.3 }}
                />
                <motion.div
                    className="absolute w-6 pointer-events-none h-[1px] bg-portfolio-gray-accent"
                    animate={showFullscreen ? { rotate: -45, y: 0 } : { rotate: 0, y: 5 }}
                    transition={{ duration: 0.3 }}
                />
            </button>


            <Sidebar showFullscreen={showFullscreen} closeFullscreen={closeFullscreenOnLink} />

        </>
    )
}
