import React, { useEffect, useState } from "react";
import { getCalApi } from "@calcom/embed-react";
import { AnimatePresence, motion } from "framer-motion";
import IconEnter from "../../assets/icons/IconEnter";


function ContactMe() {

    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredMail, setIsHoveredMail] = useState(false);

    useEffect(() => {
        (async function () {
            const cal = await getCalApi({ "namespace": "project-intro-call" });
            cal("ui", { "theme": "dark", "styles": { "branding": { "brandColor": "#121211" } }, "hideEventTypeDetails": false, "layout": "month_view" });
        })();
    }, [])

    return <div className='my-10 p-6 md:p-8 ' id="contact">
        <div className='bg-black-noise rounded-md flex flex-col justify-center items-center p-4 py-10 md:py-16 relative'>
            <span className='text-portfolio-gray opacity-60 font-Montreal-Mono'>( Need an unfair advantage? )</span>
            <h1 className='text-[14vw] md:text-[4rem] lg:text-[4rem] xl:text-[5rem] 2xl:text-[7.5rem] text-portfolio-gray opacity-90 w-10/12 md:w-7/12 2xl:w-6/12 max-w-screen-md text-center '>LET'S MAKE IT HAPPEN</h1>
            {/* <AnimatePresence mode="wait"> */}
            <motion.button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                data-cal-namespace="project-intro-call"
                data-cal-link="aneesazhar/project-intro-call" data-cal-config='{"layout":"month_view"}' className='bg-portfolio-black-accent-dark px-10 p-6 rounded-full text-xl md:text-2xl text-bold text-portfolio-gray font-Montreal-Bold overflow-hidden relative'>

                <span className={` relative transition-all duration-300 ease-in-out`}
                    style={{
                        opacity: isHovered ? 0 : 1,
                        top: isHovered ? -40 : 0
                    }}
                >
                    BOOK A CALL ↗
                </span>
                {/* animation */}

                <div className="rounded-full left-0 w-full h-full absolute flex items-center justify-center bg-[#4b4d26] transition-all duration-300 ease-in-out"
                    style={{
                        scale: isHovered ? 1 : 0.2,
                        top: isHovered ? 0 : 80,
                    }}
                >
                    <span className="rounded-full w-full h-full absolute top-0 left-0 flex items-center justify-center transition-all duration-500 ease-in-out opacity-0"
                        style={{
                            top: isHovered ? 0 : 40,
                            opacity: isHovered ? 1 : 0.2,
                        }}
                    >
                        BOOK A CALL ↗

                    </span>
                </div>
            </motion.button>
            {/* </AnimatePresence> */}

            <div className="static md:absolute mt-10 md:mt-0 z-10 right-10 bottom-10">
                <span className="text-portfolio-gray opacity-85 text-xs uppercase">For Further Enquiries</span>
                <div className="flex gap-2 text-[14px] font-Montreal-Mono text-portfolio-black-accent">
                    <motion.a href="mailto:hello@anees.one" className="overflow-hidden h-4 relative"
                        onMouseEnter={() => setIsHoveredMail(true)}
                        onMouseLeave={() => setIsHoveredMail(false)}
                    >
                        <span className="transition-all duration-300 ease-in-out relative"
                            style={{
                                top: isHoveredMail ? -20 : 0
                            }}
                        >
                            ⮡ hello@anees.one
                        </span>
                        <span className="transition-all duration-300 ease-in-out absolute left-0"
                            style={{
                                top: isHoveredMail ? 0 : 20
                            }}
                        >
                            ⮡ hello@anees.one
                        </span>
                    </motion.a>
                </div>
            </div>
        </div>
    </div>;
}

export default ContactMe;
