const MenuLinks = [
    {
        title: 'Home',
        url: '#'
    },
    {
        title: 'Services',
        url: '#services'
    },
    {
        title: 'Work',
        url: '#work'
    },
    {
        title: 'About',
        url: '#about'
    },
    {
        title: 'Contact',
        url: '#contact'
    },

]

export default MenuLinks;