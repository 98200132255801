const servicesList = [
    {
        id: 1,
        title: 'Web Development',
        paragraph: `Amplify your online presence with a website that truly connects with your audience's feelings and desires. I design stunning, high-converting sites that align with your business goals, helping you stand out and scale effectively.`,
        symbol: '&#10022;',
        services: [
            'CMS Integeration',
            'Motion & Animations',
            'Performance Optimization'
        ]
    },
    {
        id: 2,
        title: 'Web Design',
        paragraph: `Transform your online presence with sleek, responsive web designs that captivate and convert. We craft intuitive, user-friendly websites tailored to elevate your brand and engage your audience effortlessly. Let your website be a powerful reflection of your business.`,
        symbol: '&#9679;',
        services: [
            'UI/ UX',
            'Responsive Design',
            'Wireframing'
        ]
    },
    {
        id: 3,
        title: 'Skillset Overview',
        paragraph: `With a passion for crafting seamless digital experiences, I bring a refined set of skills to every project. From design to deployment, I ensure that each element contributes to a cohesive and engaging user experience. some of my skillsets include :`,
        symbol: '&#9670;',
        services: [
            'React.Js',
            'Vue.js',
            'Tailwind.CSS',
            'React Native',
            'Flutter'
        ]
    }
]

export default servicesList;