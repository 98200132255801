import React from "react";
import socialLinks from "../../assets/data/socials";
import FooterLink from "./FooterLink";
import MenuLinks from "../../assets/data/menuLinks";
import IconScrollUp from "../../assets/icons/iconScrollUp";

function Footer() {


    return <div className='h-full w-full flex-col px-6 md:px-8 footer-push lg:pb-4'>

        <div className='grid grid-cols-3 font-Montreal-Mono text-xs lg:text-normal 2xl:text-[1.2rem] gap-10'>
            {/* menu nav */}
            <div className='flex flex-col gap-2 '>
                <h1 className="pb-1 border-[0.5px] border-transparent border-b-portfolio-black-accent-dark font-Montreal-Regular ">Menu</h1>
                <div className="flex flex-col gap-1">
                    {
                        MenuLinks.map((item, key) => (
                            <FooterLink title={item.title} url={item.url} key={key} />
                        ))
                    }
                </div>
            </div>
            <div className=""></div>
            {/* social nav */}
            <div className='flex flex-col gap-2 '>
                <h1 className="pb-1 border-[0.5px] border-transparent border-b-portfolio-black-accent-dark font-Montreal-Regular ">Socials</h1>
                <div className="flex flex-col gap-2">
                    {
                        socialLinks.map((social, key) => (
                            <FooterLink isSocialLink title={social.title} url={social.url} key={key} />
                        ))
                    }
                </div>
            </div>
        </div>

        <div className='flex-row flex items-center justify-between mt-10 mb-[15svh] lg:mb-4'>
            <h1 className='text-xl md:text-4xl font-Montreal-Mono font-extrabold text-portfolio-black-accent-dark'>© 2024 Anees.<br></br>
                All rights reserved.</h1>

            <button onClick={() => window.scrollTo(0, 0)} className="rounded-full bg-portfolio-black-accent bg-opacity-30 flex items-center justify-center h-12 w-12 hover:scale-90 transition-all duration-200 ease-in-out">
                <IconScrollUp />
            </button>
        </div>
    </div>;
}

export default Footer;
