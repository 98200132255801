import React from "react";
import MyWork from "./ui/MyWork";

function MyWorkSection() {
    return <div className="mt-10 mb-10" id="work">
        <h1 id="services" className='text-[7vw] md:text-[7vw] xl:text-[7.5vw] font-medium'>SELECTED WORKS /</h1>

        <div className="flex flex-col md:flex-row my-10">
            <div className="hidden md:flex w-9/12"></div>
            <div className=' text-lg flex w-full'>
                <div className="flex flex-col lg:flex-row gap-4 md:gap-10 w-full xl:max-w-screen-sm">
                    <span className='font-Montreal-Mono opacity-50'>(PROJECTS)</span>
                    <p className="font-Montreal-Regular text-[1rem] md:text-[1.25rem]">Explore a curated selection of my recent projects, showcasing innovative design and seamless development that elevate user experiences and drive results for businesses</p>
                </div>
                <div className="hidden md:flex md:w-6/12 lg:w-4/12 xl:w-5/12 2xl:w-8/12"></div>
            </div>
        </div>
        <MyWork />
    </div>;
}

export default MyWorkSection;
