import React, { useState } from "react";
import { motion } from 'framer-motion';
import MenuLinks from "../../assets/data/menuLinks";

export default function NavLink({ title, url, last }) {

    const [hovered, setHovered] = useState(false);

    return <motion.a href={url} className="text-portfolio-black-accent font-Montreal-Mono font-semibold h-4 overflow-hidden relative capitalize w-fit"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
    >
        <span className="transition-all duration-300 ease-in-out relative"
            style={{
                top: hovered ? -15 : 0
            }}
        >
            {title}
        </span>
        <span className="transition-all duration-300 ease-in-out absolute left-0"
            style={{
                top: hovered ? 0 : 15
            }}
        >
            {title}
        </span>
        <span className={`pointer-events-none mx-1 ${last && 'hidden'}`}>,</span>
    </motion.a>;
}
