const socialLinks = [
    {
        title: 'github',
        url: 'https://github.com/Zer0-XD'
    },
    // {
    //     title: 'twitter',
    //     url: 'https://twitter.com/me_z3ro'
    // },
    {
        title: 'discord',
        url: 'https://discord.com/users/170631400427487232'
    },
    // {
    //     title: 'instagram',
    //     url: 'https://www.instagram.com/itszero.xd'
    // },
    {
        title: 'Linkedin',
        url: 'https://www.linkedin.com/in/anees-azhar/'
    },
    {
        title: 'Email',
        url: 'mailto:hello@anees.one'
    },
]

export default socialLinks
